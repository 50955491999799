import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { withRouter, Link } from "react-router-dom"
import InputMask from "react-input-mask"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import imgEmpty from "./../../assets/images/empty.png"

import {
  getPlansDetail as onGetPlansDetail,
  addAppForm as onAddAppForm,
  resetAppForm as onResetPlan,
  getCompanies as onGetCompanies,
  getBranch_byPlan as onGetBranch_byPlan,
  getCities as onCities,
  getDistricts as onDistricts,
  getNeighborhoods as onNeighborhoods,

} from "store/actions"

import { isEmpty, map } from "lodash"

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import Dropzone from "react-dropzone"

import classnames from "classnames"
import imgEmfpty from "./../../assets/images/empty.png"

import "./datatables.scss"  
const Plans = props => {
  const ImgBase = "https://api.buqrdaisvar.com/" + "static/uploads/" //process.env.REACT_APP_BASE_API
 
  const dispatch = useDispatch()
  const [planObjects, setPlanObjects] = useState([])
  const [groupInputs, setGroupInputs] = useState([])
  const [form, setForm] = useState(null)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_kvkk, setconfirm_kvkk] = useState(false)
  const [confirm_Identitiy, setconfirm_Identity] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [selectedFiles, setselectedFiles] = useState([])
  const [modal, setModal] = useState(false)
  const [modalKvkk, setModalKvkk] = useState(false)
  const [kvkk, setKvkk] = useState(false)
  const [gender, setGender] = useState("")
  const [selectedCity, setSelectedCity] = useState("")
  const [selectedDistrict, setSelectedDistrict] = useState("")
  const [selectedNeighborhood, setSelectedNeighborhood] = useState("")
  const [cities, setCities] = useState([])
  const [districts, setDistricts] = useState([])
  const [neighborhoods, setNeighborhoods] = useState([])
  const [isTrueIdentityNumber, setIsTrueIdentityNumber] = useState(false)

  const {
    error,
    Plan,
    Form,
    success,
    Company,
    Branch,
    l_cities,
    l_districts,
    l_neighborhoods,
  } = useSelector(state => ({
    error: state.Plans?.error,
    success: state.AppForm?.success,
    Form: state.Plans?.myPlans[0],
    Plan: state.Plans?.myPlans[0]?.PlanObjects,
    Company: state.Companies?.data[0],
    Branch: state.Plans?.branch[0],
    l_cities: state.Locations?.cities,
    l_districts: state.Locations?.districts,
    l_neighborhoods: state.Locations?.neighborhoods,
  }))
  const {
    match: { params },
  } = props

  useEffect(() => {
    dispatch(onGetPlansDetail(params.id))
    dispatch(onGetBranch_byPlan(params.id))

    dispatch(onGetCompanies())
    dispatch(onCities())


  }, [dispatch])


  useEffect(() => {
    setCities(l_cities)
    setDistricts(l_districts)
    setNeighborhoods(l_neighborhoods)
  }, [l_cities, l_districts, l_neighborhoods])

  const toggle = () => {
    setModal(!modal)
  }
  const toggleKvkk = () => {
    setModalKvkk(!modalKvkk)
  }
  useEffect(() => {
    if (!isEmpty(Plan)) {
      let tempPlan = Plan
      tempPlan.forEach(element => {
        if (element.isType == "group" || element.isType == "singleGroup") {
          let temp2 = element.isPlan
          if (!isEmpty(temp2)) {
            element.isValue = []
            let t2 = []
            let counter = 0
            temp2.forEach(element2 => {
              let temp3 = element2
              //temp3.title = element2.title + "_" + element.isValue.length;
              let mod = getGroupItemMod(element.key)

              temp3.isValue = "" 
              if ((mod > 0 && counter % mod == 0) || mod == 0) {
                temp3.isValue = temp3.targetValue
              } else if ((mod == -1 && element.key == 'isDeneyimi') && (temp3.title == "Görev" || temp3.title == "Sektör")) {
                temp3.isValue = temp3.targetValue 
              }

              t2.push(temp3)
              counter++
            })
            element.isValue.push(t2)
          }
        }
      })

      setPlanObjects(tempPlan)
    }
  }, [Plan])

  useEffect(() => {
    console.log("success", success, Form?.returnURL, new Date())
    if (success == true) {
      let url = `${process.env.REACT_APP_DOMAIN}/ik/finished/${params.id}${!isEmpty(Form?.returnURL) ? "?url=" + Form?.returnURL.url : ""
        }`
      console.log("success ok", success, url, new Date())
      window.location.href = `${process.env.REACT_APP_DOMAIN}/ik/finished/${params.id
        }${!isEmpty(Form?.returnURL) ? "?url=" + Form?.returnURL.url : ""}`
    }
  }, [success])

  let user = JSON.parse(localStorage.getItem("authUser"))
  const isIdentitiy = (value) => {
    let tek = 0
    let cift = 0
    let sonuc = 0
    let TCToplam = 0
    tek = parseInt(value[0]) + parseInt(value[2]) + parseInt(value[4]) + parseInt(value[6]) + parseInt(value[8]);
    cift = parseInt(value[1]) + parseInt(value[3]) + parseInt(value[5]) + parseInt(value[7]);
    tek = tek * 7;
    sonuc = Math.abs(tek - cift);
    for (let i = 0; i < 10; i++) {
      TCToplam += parseInt(value[i]);
    }
    if(value.length != 11){
      return false;
     
    }else if(value[0] ==0){
      return false;
     
    }
    else if (sonuc % 10 != value[9]) {
      return false;
     
    }
    else if (TCToplam % 10 != value[10]) {
      return false;
    }
    
    else{
      return true;
    
    }
  }
  const handleValidPlanSubmit = (e, values) => {
    let  identityNumber = values["identityNumber"]
     if(isIdentitiy(identityNumber)==false && !isEmpty(identityNumber)){
    setconfirm_Identity(true)
     }
    else if (values["eFaturaMukellef"] == true) {
      let _city = values["city"]
        ? JSON.stringify({
          id: values["city"],
          name: cities.filter(x => x.id == values["city"])[0]?.name,
        })
        : null;
      let _district = values["district"]
        ? JSON.stringify({
          id: values["district"],
          name: districts.filter(x => x.id == values["district"])[0]?.name,
        })
        : null;
      let _neighborhood = values["neighborhood"]
        ? JSON.stringify({
          id: values["neighborhood"],
          name: neighborhoods.filter(x => x.id == values["neighborhood"])[0]
            .name,
        })
        : null;
      const newForm = {
        FirstName: values["FirstName"],
        LastName: values["LastName"],
        email: values["email"],
        birthday: values["birthday"],
        identityNumber: values["identityNumber"],
        city: _city,
        district: _district,
        neighborhood: _neighborhood,
        phone: values["phone"],
        photo: values["photo"],
        CompanyId: Form.CompanyId,
        BranchId: Form.BranchId,
        AppPlanId: Form._id,
        PlanObjects: JSON.stringify(planObjects),
        DutyId: Form?.DutyId,
        ParentBranchId: Branch?.parentId
      }
     
      setForm(newForm)
      setconfirm_alert(true)
      //dispatch(onAddAppForm(newForm))
    } else {
      setconfirm_kvkk(true)
    }
  }
 
  const handleChangeInputBoolean = (i, e, inputName) => {
    const values = [...planObjects]
    values[i][inputName] =
      e.target.value === "" ? false : e.target.value == "false" ? true : false
    setPlanObjects(values)
  }
  const handleChangeInputGroupBoolean = (i, i2, i3, e, inputName) => {
    const values = [...planObjects]
    values[i]["isValue"][i2][i3][inputName] =
      e.target.value === "" ? false : e.target.value == "false" ? true : false
    setPlanObjects(values)
  }
  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY")
      return date1
    } else {
      return ""
    }
  }
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD") || ""

      return date1
    } else {
      return ""
    }
  }
  const handleChangeInput = (i, e, inputName, title) => {
    const values = [...planObjects]

    values[i]["title"] = title
    values[i][inputName] = e.target.value
    setPlanObjects(values)
  }
  const handleChangeGroupInput = (i, i2, i3, e, inputName) => {
    const values = [...planObjects]
    values[i]["isValue"][i2][i3][inputName] = e.target.value

    //console.log(`[${i}]["isValue"][${i2}][${i3}][${inputName}]`, values[i]["isValue"][i2]);

    setPlanObjects([...values])
  }
  const handleChangeGroupInputV2 = (i, i2, i3, value, inputName) => {
    const values = [...planObjects]
    values[i]["isValue"][i2][i3][inputName] = value

    //console.log(`[${i}]["isValue"][${i2}][${i3}][${inputName}]`, values[i]["isValue"][i2]);

    setPlanObjects([...values])
  }
  //IMAGE CONTROL
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const handleSubtract = index => {
    let temp = planObjects
    if (
      temp[index].isType == "group" &&
      !isEmpty(temp[index].isPlan) &&
      typeof temp[index].isValue == "object" &&
      temp[index].isValue.length > 0
    ) {
      const values = [...planObjects]
      let deletedIndex = values[index].isValue.length - 1
      values[index].isValue.splice(deletedIndex, 1)
      setPlanObjects([...values])
    }
  }
  const handleAddGroupItem = (index, key = "") => {
    let temp = planObjects
    if (
      temp[index].isType == "group" &&
      !isEmpty(temp[index].isPlan) &&
      typeof temp[index].isValue == "object" &&
      temp[index].isValue.length > 0
    ) {
      let mod = getGroupItemMod(key)

      let i2 = temp[index].isValue.length
      let _newObject = []
      let counter = 0
      temp[index].isPlan.forEach(element => {
        if ((mod > 0 && counter < mod) || mod == 0 || mod == -1) {
          _newObject.push({
            title: element.title,
            isType: element.isType,
            isValue: "",
            isOptions: element?.isOptions,
          })
        }
        counter++
      })
      temp[index].isValue.splice(i2, 0, _newObject)
      //temp[index].isValue.push(_newObject);
      setPlanObjects([...temp])
    }
  }

  const getGroupItemMod = key => {
    let mod = 0
    switch (key) {
      case "yabanciDil":
        mod = 4
        break
      case "ehliyet":
        mod = 2
        break
      case "programBilgisi":
        mod = 2
        break
      case "isDeneyimi":
        mod = -1
        break
      default:
        break
    }
    return mod
  }

  const dropAutoSelectForTarget = (index, indexArray, indexGroup, groupItem, plan) => {
    let _disabled = false
    let isValue = ""
    let mod = 0
    if (isEmpty(groupItem.targetValue) || isEmpty(plan.key)) {
      isValue = groupItem.isValue
    } else {
      mod = getGroupItemMod(plan.key)
      if (mod > 0) {
        _disabled = indexGroup % mod == 0 ? true : false
        isValue =
          indexGroup % mod == 0 ? groupItem.targetValue : groupItem.isValue
        if (_disabled) {
          //handleChangeGroupInputV2(index, indexArray, indexGroup, isValue, "isValue")
        }
      } else if (mod == -1 && (groupItem.title == "Görev" || groupItem.title == "Sektör")) {
        _disabled = true
        isValue = groupItem.targetValue
      }
    }

    return (
      <AvField
        key={"g_" + index + "_" + indexArray + "_" + indexGroup}
        id={
          "planObjectsGroup_" +
          index +
          "_" +
          indexArray +
          "_" +
          indexGroup +
          "__isValue"
        }
        name={
          "planObjectsGroup_" +
          index +
          "_" +
          indexArray +
          "_" +
          indexGroup +
          ".isValue"
        }
        type="select"
        className="form-select"
        disabled={_disabled}
        label={groupItem.title}
        value={isValue}
        onChange={e =>
          handleChangeGroupInput(index, indexArray, indexGroup, e, "isValue")
        }
        validate={{
          required: { value: false },
        }}
      >
        {groupItem.isOptions?.split(",")?.map((groupItem, indexArray) => (
          <option key={"j_" + indexArray} value={groupItem}>
            {groupItem}
          </option>
        ))}
      </AvField>
    )
  }

  const GroupItem = (item, indexArray, itemArray, index) => {
    let mod = getGroupItemMod(item.key)

    let colsize =
      itemArray.length == 1
        ? 12
        : itemArray.length == 2
          ? 6
          : isEmpty(item.key)
            ? 4
            : getGroupItemMod(item.key) == 0
              ? 4
              : 12 / getGroupItemMod(item.key)

    //console.log(item, indexArray, itemArray, index, colsize)
    if (mod == 0) {
      return (
        <Row
          style={{
            borderBottom: "1px solid #d5d5d5",
            paddingBottom: 10,
            marginBottom: 5,
          }}
        >
          {itemArray.map((groupItem, indexGroup) =>
            GroupItemObj(
              item,
              indexArray,
              index,
              indexGroup,
              groupItem,
              colsize
            )
          )}
        </Row>
      )
    } else if (mod == -1 && item.key == 'isDeneyimi') {
      return <Row
        style={{
          borderBottom: "1px solid #d5d5d5",
          paddingBottom: 10,
          marginBottom: 5,
        }}
      >
        {itemArray.map((groupItem, indexGroup) =>
          GroupItemObj(
            item,
            indexArray,
            index,
            indexGroup,
            groupItem,
            colsize
          )
        )}
      </Row>
    }
    else {
      const rows = [...Array(Math.ceil(itemArray.length / mod))]
      const productRows = rows.map((row, idx) =>
        itemArray.slice(idx * mod, idx * mod + mod)
      )
      const content = productRows.map((row, idx) => (
        <Row
          key={idx}
          style={{
            borderBottom: "1px solid #d5d5d5",
            paddingBottom: 10,
            marginBottom: 5,
          }}
        >
          {row.map((groupItem, idx2) =>
            GroupItemObj(item, indexArray, index, idx2, groupItem, colsize)
          )}
        </Row>
      ))
      return content
    }
  }

  const GroupItemObj = (item, indexArray, index, indexGroup, groupItem, colsize) => {
    return (
      <Col
        key={"g1_" + index + "_" + indexArray + "_" + indexGroup}
        className="mt-1"
        md={colsize}
      >
        {groupItem.isType === "string" ? (
          <AvField
            key={"g_" + index + "_" + indexArray + "_" + indexGroup}
            id={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              "__isValue"
            }
            name={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              ".isValue"
            }
            type="text"
            label={groupItem.title}
            placeholder={groupItem.title}
            value={groupItem.isValue}
            onChange={e => {
              handleChangeGroupInput(
                index,
                indexArray,
                indexGroup,
                e,
                "isValue"
              )
            }}
            validate={{
              required: { value: false },
            }}
          />
        ) : groupItem.isType === "date" ? (
          <AvField
            key={"g_" + index + "_" + indexArray + "_" + indexGroup}
            id={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              "__isValue"
            }
            name={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              ".isValue"
            }
            type="date"
            label={groupItem.title}
            placeholder={groupItem.title}
            value={handleValidDateAvField(groupItem.isValue)}
            onChange={e =>
              handleChangeGroupInput(
                index,
                indexArray,
                indexGroup,
                e,
                "isValue"
              )
            }
            validate={{
              required: { value: false },
            }}
          />
        ) : groupItem.isType === "number" ? (
          <AvField
            key={"g_" + index + "_" + indexArray + "_" + indexGroup}
            id={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              "__isValue"
            }
            name={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              ".isValue"
            }
            type="number"
            label={groupItem.title}
            placeholder={groupItem.title}
            value={groupItem.isValue}
            onChange={e => {
              handleChangeGroupInput(
                index,
                indexArray,
                indexGroup,
                e,
                "isValue"
              )
            }}
            validate={{
              required: { value: false },
            }}
          />
        ) : groupItem.isType === "boolean" ? (
          <AvField
            key={"g_" + index + "_" + indexArray + "_" + indexGroup}
            id={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              "__isValue"
            }
            name={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              ".isValue"
            }
            type="checkbox"
            label={groupItem.title}
            //placeholder={item.title}
            value={groupItem.isValue == "" ? false : groupItem.isValue}
            onChange={e =>
              handleChangeInputGroupBoolean(
                index,
                indexArray,
                indexGroup,
                e,
                "isValue"
              )
            }
            validate={{
              required: { value: false },
            }}
          />
        ) : groupItem.isType === "dropdown" ? (
          dropAutoSelectForTarget(index, indexArray, indexGroup, groupItem, item)
        ) : groupItem.isType === "longText" ? (
          <AvField
            key={"g_" + index + "_" + indexArray + "_" + indexGroup}
            id={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              "__isValue"
            }
            name={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              ".isValue"
            }
            type="textarea"
            label={groupItem.title}
            placeholder={groupItem.title}
            value={groupItem.isValue}
            onChange={e => {
              handleChangeGroupInput(
                index,
                indexArray,
                indexGroup,
                e,
                "isValue"
              )
            }}
            validate={{
              required: { value: false },
            }}
          />
        ) : groupItem.isType === "phone" ? (
          <AvField
            key={"g_" + index + "_" + indexArray + "_" + indexGroup}
            id={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              "__isValue"
            }
            name={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              ".isValue"
            }
            type="text"
            label={groupItem.title}
            mask="(999) 999-9999"
            maskChar="-"
            tag={[Input, InputMask]}
            placeholder="(999) 999-9999"
            value={item.isValue}
            onChange={e =>
              handleChangeGroupInput(
                index,
                indexArray,
                indexGroup,
                e,
                "isValue"
              )
            }
            validate={{
              required: { value: false },
            }}
          />
        ) : (
          <AvField
            key={"g_" + index + "_" + indexArray + "_" + indexGroup}
            id={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              "__isValue"
            }
            name={
              "planObjectsGroup_" +
              index +
              "_" +
              indexArray +
              "_" +
              indexGroup +
              ".isValue"
            }
            type="text"
            label={groupItem.title}
            placeholder={groupItem.title}
            value={groupItem.isValue}
            onChange={e => {
              handleChangeGroupInput(
                index,
                indexArray,
                indexGroup,
                e,
                "isValue"
              )
            }}
            validate={{
              required: { value: false },
            }}
          />
        )}
      </Col>
    )
  }
 
  return (
    <React.Fragment>
      <div className="container" style={{ marginTop: "15px" }}>
        <Container>
          {/* Render Breadcrumb */}
          <Row>
            {/* <Col style={{ position: "relative", top: "99px" }}>
              <Breadcrumb breadcrumbItem={props.t("Job Application Form")} />
            </Col> */}
            <Col md={6} className="order-md-3 offset-md-3">
              <div style={{ textAlign: "center" }}>
                <div className="card">
                  <div className="card-body company-info">
                    <div>
                      {Branch?.photo ? (
                        <img
                          src={ImgBase + Branch?.photo}
                          style={{
                            width: "100%",
                            height: "120px",
                            objectFit: "contain",
                            paddingLeft: 13,
                          }}
                        />
                      ) : (
                        <div style={{ margin: "10px" }}>
                          <h5>{Branch?.name}</h5>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div><p>{Branch?.description}</p></div> */}
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col style={{ position: "relative", top: "99px" }}>
              <Breadcrumb breadcrumbItem={props.t("Job Application Form")} />
            </Col> */}
            <Col md={6} className="order-md-3 offset-md-3">
              <div style={{ textAlign: "center" }}>
                <div className="card">
                  <div className="card-body company-info">
                    <div>
                      <b>{Form?.name}</b>
                    </div>
                  </div>
                </div>
                <div>
                  <p>{Form?.description}</p>
                </div>
              </div>
            </Col>
          </Row> 
          {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}
          <AvForm onValidSubmit={(e, v)=>{
            console.log("onValidSubmit",e);
            handleValidPlanSubmit(e, v)
            }} onInvalidSubmit={(event, errors, values)=>{  
              setTimeout(() => {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 0);
            }} 
            >
            <Row form>
              <Col md={6} className="order-md-3 offset-md-3">
                <Row>
                  <div className="mb-3">
                    <div className="mb-3">{Form?.planDesc}</div>
                  </div>

                  <div
                    className="mb-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div>
                      <img
                        src={imgEmpty}
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: "75%",
                        border: "1px solid #ced4da",
                        borderRadius: "10px",
                        height: "150px",
                      }}
                    >
                      <Dropzone
                        multiple={false}
                        accept="image/jpeg, image/png"
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone-min">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div
                                className="dz-message needsclick"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <i className="display-5 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>{props.t("Drop Image")}</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      <div
                        className="dropzone-previews"
                        id="file-previews"
                        style={{
                          position: "absolute",
                          zIndex: 99,
                          left: 0,
                          top: -5,
                        }}
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <div className="align-items-center">
                                  <div className="col-12">
                                    <img
                                      data-dz-thumbnail=""
                                      style={{
                                        width: "131px",
                                        height: "135px",
                                        objectFit: "contain",
                                      }}
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <Col md={6}>
                    <div className="mb-3 mt-3">
                      <AvField
                        name="FirstName"
                        label={props.t("First Name")}
                        type="text"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: true },
                        }}
                        value={""} 
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3 mt-3">
                      <AvField
                        name="LastName"
                        label={props.t("Last Name")}
                        type="text"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: true },
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                  <div className="mb-3"></div>

                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="email"
                        label={props.t("Email")}
                        type="text"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: false },
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="identityNumber"
                        label={props.t("Identitiy Number")}
                        type="number"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: false },
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="birthday"
                        label={props.t("Birthday")}
                        type="date"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: false },
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="phone"
                        label={props.t("Phone Number")}
                        type="text"
                        mask="(999) 999-9999"
                        maskChar="-"
                        tag={[Input, InputMask]}
                        placeholder="(999) 999-9999"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: true },
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <AvField
                        id={"city"}
                        name={"city"}
                        type="select"
                        className="form-select"
                        label={props.t("City")}
                        value={selectedCity}
                        onChange={e => {
                          setSelectedCity(e.target.value)
                          setSelectedDistrict("")
                          setSelectedNeighborhood("")
                          setDistricts([])
                          setNeighborhoods([])
                          if (!isEmpty(e.target.value)) {
                            dispatch(onDistricts(e.target.value))
                          }
                        }}
                        validate={{
                          required: { value: false },
                        }}
                      >
                        <option>{props.t("Select")}</option>
                        {cities?.map((item, index) => (
                          <option key={"city_" + index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <AvField
                        id={"district"}
                        name={"district"}
                        type="select"
                        className="form-select"
                        label={props.t("District")}
                        value={selectedDistrict}
                        onChange={e => {
                          setSelectedDistrict(e.target.value)
                          setSelectedNeighborhood("")
                          setNeighborhoods([])
                          if (!isEmpty(e.target.value)) {
                            dispatch(
                              onNeighborhoods(selectedCity, e.target.value)
                            )
                          }
                        }}
                        validate={{
                          required: { value: false },
                        }}
                      >
                        <option>{props.t("Select")}</option>
                        {districts?.map((item, index) => (
                          <option key={"district_" + index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <AvField
                        id={"neighborhood"}
                        name={"neighborhood"}
                        type="select"
                        className="form-select"
                        label={props.t("Neighborhood")}
                        value={selectedNeighborhood}
                        onChange={e => {
                          setSelectedNeighborhood(e.target.value)
                        }}
                        validate={{
                          required: { value: false },
                        }}
                      >
                        <option>{props.t("Select")}</option>
                        {neighborhoods?.map((item, index) => (
                          <option key={"neighborhood_" + index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>
                <hr />

                {map(Plan, (item, index) => (
                  <div className="mb-2" key={"h_" + index}>
                    {item.isType === "string" ? (
                      <AvField
                        key={"h1_" + index}
                        id={"planObjects" + index + "__isValue"}
                        name={"planObjects[" + index + "].isValue"}
                        type="text"
                        label={item.title}
                        placeholder={item.title}
                        //value={item.isValue}
                        onChange={e =>
                          handleChangeInput(index, e, "isValue", item.title)
                        }
                        validate={{
                          required: { value: false },
                        }}
                      />
                    ) : item.isType === "longText" ? (
                      <AvField
                        key={"h1_" + index}
                        id={"planObjects" + index + "__isValue"}
                        name={"planObjects[" + index + "].isValue"}
                        type="textarea"
                        label={item.title}
                        placeholder={item.title}
                        //value={item.isValue}
                        onChange={e =>
                          handleChangeInput(index, e, "isValue", item.title)
                        }
                        validate={{
                          required: { value: false },
                        }}
                      />
                    ) : item.isType === "title" ? (
                      <div className="mt-2">
                        <br />
                        <h4>{item.title}</h4>
                      </div>
                    ) : item.isType === "date" ? (
                      <AvField
                        key={"h1_" + index}
                        id={"planObjects" + index + "__isValue"}
                        name={"planObjects[" + index + "].isValue"}
                        type="date"
                        label={item.title}
                        placeholder={item.title}
                        value={handleValidDateAvField(item.isValue)}
                        onChange={e =>
                          handleChangeInput(index, e, "isValue", item.title)
                        }
                        validate={{
                          required: { value: false },
                        }}
                      />
                    ) : item.isType === "number" ? (
                      <AvField
                        key={"h1_" + index}
                        id={"planObjects" + index + "__isValue"}
                        name={"planObjects[" + index + "].isValue"}
                        type="number"
                        label={item.title}
                        //placeholder={item.title}
                        value={item.isValue}
                        onChange={e =>
                          handleChangeInput(index, e, "isValue", item.title)
                        }
                        validate={{
                          required: { value: false },
                        }}
                      />
                    ) : item.isType === "phone" ? (
                      <AvField
                        key={"h1_" + index}
                        id={"planObjects" + index + "__isValue"}
                        name={"planObjects[" + index + "].isValue"}
                        type="text"
                        label={item.title}
                        mask="(999) 999-9999"
                        maskChar="-"
                        tag={[Input, InputMask]}
                        placeholder="(999) 999-9999"
                        value={item.isValue}
                        onChange={e =>
                          handleChangeInput(index, e, "isValue", item.title)
                        }
                        validate={{
                          required: { value: false },
                        }}
                      />
                    ) : item.isType === "group" ||
                      item.isType === "singleGroup" ? (
                      <Row>
                        <Col className="mt-2" sm={6} md={6} lg={6} xl={6} xs={6}  >
                          <div>
                            <h4>{props.t(item.title)}</h4>
                          </div>
                        </Col>

                        {item.isType === "group" ? (
                          <Col className="text-end d-flex flex-row justify-content-end align-items-center">
                            <span className="pt-2">
                              <h5 className="me-1">
                                {props.t(item.title) + " " + props.t("Add")}
                              </h5>
                            </span>

                            <button
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              type="button"
                              className="btn btn-success save-user me-2"
                              onClick={() => {
                                handleAddGroupItem(index, item.key)
                              }}
                            >
                              <i className="bx bx-plus font-size-16 align-middle"></i>
                            </button>
                            {planObjects[index]?.isValue?.length > 1 ? (
                              <button
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                type="button"
                                className="btn btn-secondary save-user me-2"
                                onClick={() => {
                                  handleSubtract(index)
                                }}
                              >
                                <i className="bx bx-minus font-size-16 align-middle"></i>
                              </button>
                            ) : null}
                          </Col>
                        ) : null}

                        {typeof item.isValue == "object" &&
                          item.isValue.map((itemArray, indexArray) => (
                            <div key={"g_" + index + "_" + indexArray}>
                              {typeof itemArray == "object" &&
                                GroupItem(item, indexArray, itemArray, index)}
                            </div>
                          ))}
                      </Row>
                    ) : item.isType === "boolean" ? (
                      <AvField
                        key={"h1_" + index}
                        id={"planObjects" + index + "__isValue"}
                        name={"planObjects[" + index + "].isValue"}
                        type="checkbox"
                        label={item.title}
                        //placeholder={item.title}
                        value={item.isValue == "" ? false : item.isValue}
                        onChange={e =>
                          handleChangeInputBoolean(
                            index,
                            e,
                            "isValue",
                            item.title
                          )
                        }
                        validate={{
                          required: { value: false },
                        }}
                      />
                    ) : item.title == "Cinsiyet" ? (
                      <AvField
                        key={"h1_" + index}
                        id={"planObjects" + index + "__isValue"}
                        name={"planObjects[" + index + "].isValue"}
                        type="select"
                        className="form-select"
                        label={item.title}
                        //placeholder={item.title}
                        value={item.isValue}
                        onChange={e => {
                          handleChangeInput(index, e, "isValue", item.title)
                          setGender(e.target.value)
                        }
                        }
                        validate={{
                          required: { value: false },
                        }}
                      >
                        {item.isOptions.split(",")?.map((item, index) => (
                          <option key={"j_" + index} value={item}>
                            {item}
                          </option>
                        ))}
                      </AvField>
                    ) : item.title == "Askerlik Durumu" ? (
                      gender == "Kadın" ? (null) : (
                        <AvField
                          key={"h1_" + index}
                          id={"planObjects" + index + "__isValue"}
                          name={"planObjects[" + index + "].isValue"}
                          type="select"
                          className="form-select"
                          label={item.title}
                          //placeholder={item.title}
                          value={item.isValue}
                          onChange={e =>
                            handleChangeInput(index, e, "isValue", item.title)
                          }
                          validate={{
                            required: { value: false },
                          }}
                        >
                          {item.isOptions.split(",")?.map((item, index) => (
                            <option key={"j_" + index} value={item}>
                              {item}
                            </option>
                          ))}
                        </AvField>
                      )



                    ) :
                      item.isType === "dropdown" ? (

                        <AvField
                          key={"h1_" + index}
                          id={"planObjects" + index + "__isValue"}
                          name={"planObjects[" + index + "].isValue"}
                          type="select"
                          className="form-select"
                          label={item.title}
                          //placeholder={item.title}
                          value={item.isValue}
                          onChange={e =>
                            handleChangeInput(index, e, "isValue", item.title)
                          }
                          validate={{
                            required: { value: false },
                          }}
                        >
                          {item.isOptions.split(",")?.map((item, index) => (
                            <option key={"j_" + index} value={item}>
                              {item}
                            </option>
                          ))}
                        </AvField>
                      ) : (
                        <AvField
                          key={"h1_" + index}
                          id={"planObjects" + index + "__isValue"}
                          name={"planObjects[" + index + "].isValue"}
                          type="text"
                          label={item.title}
                          placeholder={item.title}
                          value={item.isValue}
                          onChange={e =>
                            handleChangeInput(index, e, "isValue", item.title)
                          }
                          validate={{
                            required: { value: false },
                          }}
                        />
                      )}
                    {item.isType === "group" ||
                      item.isType === "singleGroup" ? null : <hr />}
                  </div>

                ))}
                <p>
                  <span>
                    Kişisel Verilerin Korunması Kanunu kapsamında Çalışan Adayı
                    Aydınlatma Metni'ne{" "}
                    <a
                      style={{ color: "blue" }}
                      onClick={() => {
                        toggleKvkk()
                      }}
                      target="_blank"
                    >
                      buradan{" "}
                    </a>
                    ulaşabilirsiniz.
                  </span>
                </p>
                <AvGroup check>
                  <AvInput
                    type="checkbox"
                    label="eFaturaMukellef"
                    value="false"
                    name="eFaturaMukellef"
                    onChange={e => setKvkk(e.target.value)}
                  />
                  <Label check for="checkbox">
                    {" "}
                    <p>
                      <span>
                        KVKK uyarınca kişisel verilerimin{" "}
                        <a
                          style={{ color: "blue" }}
                          onClick={() => {
                            toggle()
                          }}
                          target="_blank"
                        >
                          Açık Rıza Metni
                        </a>
                        'nde belirtilen hususlar kapsamında işlenmesini ve
                        aktarılmasını kabul ediyorum.
                      </span>
                    </p>
                  </Label>
                </AvGroup>
              </Col>
            </Row>
            <Row></Row>

            <Row>
              <Col lg={6} className="order-md-3 offset-md-3">
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    {props.t("Save")}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
          <Modal isOpen={modal} size="xl" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              AÇIK RIZA ESASLARI PROSEDÜRÜ
            </ModalHeader>
            <ModalBody>
              <p>
                Bu Açık Rıza Esasları Prosedürünün (“Prosedür”) amacı; 6698
                sayılı Kişisel Verilerin Korunması Hakkında Kanun (“Kanun”)’a
                dayalı olarak ve Kişisel Verileri Koruma Kurulu’nun (“Kurul”)
                tebliğ ve rehberleri dikkate alınarak özel verilerin korunmasına
                ilişkin yükümlülüklerin yerine getirilmesi için Bu QR'da iş var
                (“Şirket”) genelinde uygulanacak kurallar ile rol ve
                sorumlulukları belirlemektir.
              </p>
              <p>
                PROSEDÜR, Şirket nezdinde tutulan, Kanun ile tanımlanan Kişisel
                Verileri ve Özel Nitelikli Kişisel Verileri, tüm Şirket
                çalışanlarını, yöneticilerini, danışmanlarını ve Kişisel Veri
                paylaşımı söz konusu olan tüm durumlarda iştiraklerini, dış
                hizmet sağlayıcılarını ve Şirket’in sair hukuki ilişkiye girdiği
                gerçek ve tüzel kişileri kapsamaktadır. Prosedür Kanun’da
                belirtildiği şekilde, tamamen veya kısmen otomatik olan ya da
                herhangi bir veri kayıt sisteminin parçası olmak kaydıyla
                otomatik olmayan yollarla verilerin işlendiği sistemlerde yer
                alan Kişisel Verileri kapsamaktadır. Prosedür’ de aksi
                belirtilmedikçe Kişisel Veriler ve Özel Nitelikli Kişisel
                Veriler birlikte “Kişisel Veriler” olarak adlandırılacaktır.
              </p>
              <p>
                <strong>III. TANIMLAR</strong>
              </p>
              <p>
                <strong>Açık Rıza</strong> “belirli bir konuya ilişkin,
                bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza”yı
                <br />
                <strong>İlgili kişi: </strong>Kişisel Verisi işlenen gerçek
                kişiyi,
                <br />
                <strong>Kişisel Veri: </strong>Kimliği belirli veya
                belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,
                <br />
                kategorisi, aktarılan alıcı grubu ve veri konusu kişi grubuyla
                ilişkilendirerek oluşturdukları ve Kişisel Verilerin
                işlendikleri amaçlar için gerekli olan azami süreyi, yabancı
                ülkelere aktarımı öngörülen Kişisel Verileri ve veri güvenliğine
                ilişkin alınan tedbirleri açıklayarak detaylandırdıkları
                envanteri,
                <br />
                <strong>Komite: </strong>Kişisel Verilerin korunması kapsamında
                Şirket’in başta İnsan Kaynakları, Bilgi Teknolojileri ve ilgili
                diğer departmanlarından temsilcilerinden oluşan, Yönetim Kurulu
                tarafından görevlendirilmek suretiyle yetkilendirilmiş olan
                komiteyi,
                <br />
                <strong>Özel Nitelikli Kişisel Veri: </strong>Kişilerin ırkı,
                etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi
                veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da
                sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve
                güvenlik tedbirleri vs. ile ilgili verileri ile biyometrik ve
                genetik verilerini,
                <br />
                <strong>Veri kayıt sistemi: </strong>Kişisel Verilerin belirli
                kriterlere göre yapılandırılarak işlendiği kayıt sistemini,
                <br />
                <strong>Şirket: </strong>Kişisel Verilerin işleme amaçlarını ve
                vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve
                yönetilmesinden sorumlu olan gerçek veya tüzel kişi, yani veri
                sorumlusu sıfatını taşıyan Bu QR'da iş var, ifade eder.
              </p>

              <p>
                <strong>
                  IV. PROSEDÜR İLE DÜZENLEME ALTINA ALINAN KAYIT ORTAMLARI
                </strong>
              </p>

              <p>
                Tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt
                sisteminin parçası olmak kaydıyla otomatik olmayan yollarla
                işlenen Kişisel Verilerin bulunduğu her türlü ortam kayıt ortamı
                kapsamına girer.
              </p>

              <p>
                <strong>V. AÇIK RIZA ALINMA İLKELERİ</strong>
              </p>

              <p>
                Kanun çerçevesinde Açık Rıza, kişinin sahip olduğu verinin
                işlenmesine, kendi isteği ile ya da karşı taraftan gelen istek
                üzerine, onay vermesi anlamını taşımaktadır. Açık Rızanın bir
                diğer önemi de veri işleyene gerçekleştireceği fiil konusunda
                yol göstermesidir. Kişi Açık Rıza açıklaması ile aslında veri
                sorumlusuna kendi hukuksal değerine ilişkin verdiği kararı
                bildirmiş olmaktadır. Açık Rıza, ilgili kişinin, işlenmesine
                izin verdiği verinin sınırlarını, kapsamını, gerçekleştirilme
                biçimini ve süresini de belirlemesini sağlayacaktır.
              </p>

              <p>
                Açık Rızanın bu anlamda, rıza veren kişinin “olumlu irade
                beyanı”nı içermesi gerekmektedir. Diğer mevzuattaki düzenlemeler
                saklı kalmak üzere, Açık Rızanın yazılı şekilde alınmasına gerek
                yoktur. Açık Rızanın elektronik ortam ve çağrı merkezi vb.
                yollarla alınması da mümkündür. Burada ispat yükümlülüğü veri
                sorumlusuna aittir.
              </p>

              <p>
                Kanunun 3. maddesinde yer verilen Açık Rıza tanımı kapsamında,
                Açık Rızanın 3 unsuru bulunmaktadır:
              </p>

              <ul>
                <li>Belirli bir konuya ilişkin olması,</li>
                <li>Rızanın bilgilendirmeye dayanması,</li>
                <li>Özgür iradeyle açıklanması.</li>
              </ul>

              <p>
                Belirli bir konu ile sınırlandırılmayan ve ilgili işlemle
                sınırlı olmayan genel nitelikteki Açık Rızalar “blanked consent”
                olarak kabul edilmekte ve hukuken geçersiz sayılmaktadır.
                Örneğin; “her türlü ticari işlem, her türlü bankacılık işlemi ve
                her türlü veri işleme faaliyeti” gibi belirli bir konu ve
                faaliyeti işaret etmeyen rıza beyanları battaniye rıza
                kapsamında değerlendirilebilecek durumlardır.
              </p>

              <p>
                Açık Rıza vermek, kişiye sıkı sıkıya bağlı bir hak olduğundan,
                verilen Açık Rıza geri alınabilir. Bu bağlamda Kişisel Verilerin
                geleceğini belirleme hakkı ilgili kişiye ait olduğundan, kişi
                dilediği zaman veri sorumlusuna vermiş olduğu Açık Rızasını geri
                alabilir.
              </p>

              <p>
                Ancak, geri alma işlemi ileriye yönelik sonuç doğuracağından,
                Açık Rızaya dayalı olarak gerçekleştirilen tüm faaliyetler geri
                alma beyanının veri sorumlusuna ulaştığı andan itibaren veri
                sorumlusu tarafından durdurulmalıdır. Bir diğer deyişle, geri
                alma beyanı veri sorumlusuna ulaştığı andan itibaren hüküm
                doğurur.
              </p>

              <p>
                <strong>
                  VI. AÇIK RIZA ALINMASI SÜRECİNDE AYDINLATMA YAPILMASI
                  ZORUNLULUĞU
                </strong>
              </p>

              <p>
                Kanun, Kişisel Verileri işlenen ilgili kişilere bu verilerinin
                kim tarafından, hangi amaçlarla ve hukuki sebeplerle
                işlenebileceği, kimlere hangi amaçlarla aktarılabileceği
                hususunda bilgi edinme hakkı tanımakta ve bu hususları, veri
                sorumlusunun aydınlatma yükümlülüğü kapsamında ele almaktadır.
                Buna göre veri sorumlusu, Kanunun 10. maddesi çerçevesinde
                Kişisel Verilerin elde edilmesi sırasında bizzat veya
                yetkilendirdiği kişi aracılığıyla aşağıdaki bilgileri ilgili
                kişiye sağlamakla yükümlüdür:
              </p>

              <ul>
                <li>Veri sorumlusunun ve varsa temsilcisinin kimliği,</li>
                <li>Kişisel Verilerin hangi amaçla işleneceği,</li>
                <li>
                  Kişisel Verilerin kimlere ve hangi amaçla aktarılabileceği,
                </li>
                <li>Kişisel Veri toplamanın yöntemi ve hukuki sebebi,</li>
                <li>11. maddede sayılan diğer hakları.</li>
              </ul>

              <p>
                Veri işleme faaliyetinin ilgili kişinin Açık Rızasına bağlı
                olduğu veya faaliyetin Kanundaki diğer bir şart kapsamında
                yürütüldüğü durumlarda da veri sorumlusunun ilgili kişiyi
                bilgilendirme yükümlülüğü devam etmektedir.{" "}
                <strong>
                  Yani, ilgili kişi, Kişisel Verisinin işlendiği her durumda
                  aydınlatılmalıdır.
                </strong>
              </p>

              <p>
                <strong>
                  Buna göre kişiden{" "}
                  <span>
                    Açık Rıza alınmadan önce aydınlatma yapılması gerekmektedir.
                  </span>
                </strong>
              </p>

              <p>
                <strong>VII. ÖZEL VERİLER VE AÇIK RIZA İLİŞKİSİ</strong>
              </p>

              <p>
                Kanunu’nun 6. maddesinde, hukuka aykırı olarak işlendiğinde
                kişilerin mağduriyetine veya ayrımcılığa sebep olma riski
                taşıyan bir takım Kişisel Veri “özel nitelikli” olarak
                belirlenmiş olup bu verilerin işlenmesinde dikkat ve hassasiyet
                gösterilmesi gerekmektedir. Bunlar; ırk, etnik köken, siyasi
                düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık
                ve kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel
                hayat, ceza mahkûmiyeti, güvenlik tedbirleri ile ilgili veriler
                ile biyometrik ve genetik verilerdir.
              </p>

              <p>
                Özel nitelikli Kişisel Veriler, gerekli önlemlerin alınması
                kaydıyla aşağıdaki durumlarda işlenebilir:
              </p>

              <ul>
                <li>
                  Kişisel İlgili Kişinin sağlığı ve cinsel hayatı dışındaki özel
                  nitelikli Kişisel Veriler, Kanunlarda öngörülen hallerde veya
                  kişisel İlgili Kişinin Açık Rızası var ise buna dayalı olarak,
                </li>
                <li>
                  Kişisel İlgili Kişinin sağlığına ve cinsel hayatına ilişkin
                  özel nitelikli Kişisel Veriler ise ancak kamu sağlığının
                  korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım
                  hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının
                  planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü
                  altında bulunan kişiler veya yetkili kurum ve kuruluşlar
                  tarafından veya kişisel İlgili Kişinin Açık Rızası ile
                  işlenmektedir.
                </li>
              </ul>

              <p>
                Hangi nedene dayanırsa dayansın, işleme süreçlerinde daima genel
                veri işleme ilkeleri dikkate alınır ve buna ilkelere uygunluk
                sağlanır (Kanun m. 4)
              </p>

              <p>
                <strong>
                  VII. ÖZEL VERİLERİN İŞLENMESİNDE GÖZÖNÜNDE TUTULACAK KURALLAR
                </strong>
              </p>

              <table border="1" class="table-prim">
                <tbody>
                  <tr>
                    <td>
                      <strong>KİŞİLERİN IRKI, ETNİK KÖKENİ</strong>
                    </td>
                    <td>
                      <strong>
                        Kişilerin etnik kökeninin kural olarak işlenmemesi
                        esastır. İş görüşmelerinde bu konuda sorular sorulmaması
                        gerekmektedir. İK personeli bu konuda bilgilendirilecek
                        ve eğitilecektir.
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        KİŞİLERİN SİYASİ DÜŞÜNCESİ, FELSEFİ İNANCI
                      </strong>
                    </td>
                    <td>
                      Kişilerin siyasi düşüncesi, felsefi inancının kural olarak
                      işlenmemesi esastır. İş görüşmelerinde bu konuda sorular
                      sorulmaması gerekmektedir. İK personeli bu konuda
                      bilgilendirilecek ve eğitilecektir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        KİŞİLERİN DİNİ, MEZHEBİ VEYA DİĞER İNANÇLARI
                      </strong>
                    </td>
                    <td>
                      Kişilerin din bilgileri de kural olarak işlenmeyecektir.
                      Ramazan aylarında yemeğe gidecek personelin önceden
                      öğrenilmesine ilişkin sorular sorulmayacak, eski
                      kimliklerdeki din haneleri karartılarak yeniden sisteme
                      taranacaktır.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>KİŞİLERİN KILIK VE KIYAFETİ</strong>
                    </td>
                    <td>
                      Kişilerin kılık kıyafeti de kural olarak işlenmeyecektir.
                      Dini inançlarıyla bağlantılı olarak özel olarak giyinen
                      kişilere (örneğin başörtüsü takan) saygı gösterilecek, bu
                      özelliği ile ilgili kesinlikle bir kayıt alınmayacaktır.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>KİŞİLERİN DERNEK, VAKIF VERİLERİ</strong>
                    </td>
                    <td>
                      Dernek vakıf üyeliği gibi veriler de kural olarak
                      işlenmeyecek, iş görüşmelerinde bu konuda soru
                      sorulmayacaktır. İK Personeli de bu konuda eğitilecektir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>KİŞİLERİN SENDİKA ÜYELİĞİ VERİLERİ</strong>
                    </td>
                    <td>
                      Sendika üyeliği konusu kesinlikle çalışan adaylarından
                      istenmeyecek ve sorulmayacak, ancak işyerinde sendika ve
                      toplu iş sözleşmesi düzeni olması durumunda, bunlar kanun
                      gereği işlenecektir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>KİŞİLERİN CİNSEL HAYATI</strong>
                    </td>
                    <td>
                      Kişilerin cinsel hayatı ile ilgili veriler de işyeri
                      hekimi ve yetkili sağlık personeli dışında
                      işlenmeyecektir. Kişilerin cinsel hayatına yönelik sorular
                      iş görüşmelerinde kesinlikle sorulmayacak, bu konuda İK
                      personeli bilgilendirilecek ve eğitilecektir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        KİŞİLERİN CEZA MAHKÛMİYETİ VE GÜVENLİK TEDBİRLERİYLE
                        İLGİLİ VERİLERİ
                      </strong>
                    </td>
                    <td>
                      Kişilerden sabıka kayıtlarının ancak özel bir gereklilik
                      durumunda talep edilmesi esas olmalı, bu durumda da
                      ilgiliden rıza alarak işlem yapılmalıdır. Ancak ülkemizin
                      içinde bulunduğu OHAL ve terör olayları nedeniyle geçici
                      bir süreliğine bu uygulama genelleştirilebilecek, ancak bu
                      verilerin korunması konusunda işbu Prosedür ve{" "}
                      <strong>Özel Verilerin Korunması Prosedürü</strong>{" "}
                      hükümlerine uygun hareket edilecektir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>KİŞİLERİN BİYOMETRİK VE GENETİK VERİLERİ</strong>
                    </td>
                    <td>
                      Biyometrik ve genetik veriler kural olarak işlenmeyecek,
                      ancak istisnai durumlarda (özel güvenlik gerekleri gibi),
                      parmak izi retina gibi sistemler uygulanabilecektir. Bu
                      durumda,{" "}
                      <strong>Özel Verilerin Korunması Prosedürü</strong>{" "}
                      hükümlerine GÖRE veriler saklanacak,{" "}
                      <strong>
                        kişilerin Açık Rızaları alınmak suretiyle işleme
                        yapılacaktır.
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>KİŞİLERİN SAĞLIK VERİLERİ</strong>
                    </td>
                    <td>
                      Sağlık verileri kural olarak yetkili sağlık personeli
                      tarafından işlenecektir. Bu konuda sağlık verileri ile
                      ilgili kayıtlar (özellikle sağlık raporları) İK biriminde
                      tutulmayacak, bunlar sağlık personelinde bulunacaktır.
                      İlgililerin bu verileri sağlık personeline teslim etmesi
                      sağlanacak, İK personeli de bu konuda eğitilecektir.
                      <br />
                      <br />
                      <strong>
                        Engelli Raporları Ve Hastalık izinleri
                      </strong>{" "}
                      ile ilgili belgeler İK tarafından işlenmesi gerekli
                      olduğundan, bunlarla ilgili olarak Açık Rıza alınarak
                      işlem yapılacaktır.
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                <strong>IX. AÇIK RIZA GEREKTİREN DİĞER DURUMLAR</strong>
              </p>

              <p>
                İşbu Prosedürde öngörülenlerle sınırlı olmamak kaydıyla
                aşağıdaki durumlarda Açık Rıza alınarak işlem yapılması gerekli
                bulunmaktadır:
              </p>

              <table border="1" class="table-prim">
                <tbody>
                  <tr>
                    <td>
                      <strong>
                        SOSYAL MEDYA VE GENEL OLARAK MEDYA PAYLAŞIMLARI
                      </strong>
                    </td>
                    <td>
                      Çalışanların ve diğer ilgililerin resimlerinin ve
                      bilgilerinin sosyal medya ve genel olarak paylaşımı
                      konusunda (örn. Şirket dergileri) Açık Rıza alınmalıdır.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>DOĞUM/EVLİLİK GÜNÜ KUTLAMALARI</strong>
                    </td>
                    <td>
                      Çalışanların özel günlerinin kutlanması gibi uygulamalarda
                      (Şirketin intranet ortamı gibi) Açık Rıza alınmalıdır.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>PAZARLAMA SÜREÇLERİ</strong>
                    </td>
                    <td>
                      Kişilere yapılacak pazarlama aktiviteleri (pazarlama
                      amaçlı SMS, mail) kural olarak Açık Rıza ile yürütülmeli,
                      bu konuda elektronik ticaret mevzuatı hükümleri de dikkate
                      alınarak süreç yürütülmelidir. Bu noktada B2B / B2C yapı
                      dikkate alınmalı, kurul kararları göz önündü tutularak
                      işlem yapılmalıdır.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>YURT DIŞI VERİ PAYLAŞIMI</strong>
                    </td>
                    <td>
                      Kanunun 9. maddesine göre yurtdışına veri aktarımı;
                      <br />
                      <br />
                      İlgili kişinin Açık Rızasının bulunması,
                      <br />
                      Yeterli korumanın bulunduğu ülkelere (Kurul tarafından
                      güvenli kabul edilen ülkeler) veri aktarımında, Kanunda
                      belirtilen hallerin varlığı (Kanunun 5. maddesinin 2.
                      fıkrası ile 6. maddesinin 3. fıkrasında belirtilen
                      şartlar) Yeterli korumanın bulunmadığı ülkelere veri
                      aktarımında Kanunda belirtilen hallerin varlığında
                      (Kanunun 5. maddesinin 2. fıkrası ile 6. maddesinin 3.
                      fıkrasında belirtilen şartlar) yeterli korumanın yazılı
                      olarak taahhüt edilmesi ve Kurulun izninin bulunması
                      durumlarında gerçekleştirilebilir.
                      <br />
                      <br />
                      Kanun, Kişisel Verilerin işlenmesi ile bu verilerin yurt
                      dışına aktarılması bakımından aynı şartları aramaktadır.
                      Ayrıca Kişisel Verilerin yurt dışına aktarılmasında ek
                      tedbirlerin alınmasını öngörülmüştür.
                      <br />
                      <br />
                      İlgili kişinin Açık Rızasının bulunması durumunda Kişisel
                      Verilerin yurtdışına aktarılması mümkündür. Açık Rıza
                      dışındaki hallerde, Kanun Kişisel Verilerin yurtdışına
                      aktarılmasında, aktarımın yapılacağı ülkede yeterli
                      korumanın bulunup bulunmamasına göre farklı hükümler
                      getirmiştir.
                      <br />
                      <br />
                      <strong>A) Yeterli korumanın bulunması halinde</strong>
                      <br />
                      Kişisel Veriler;
                      <br />
                      Kanunlarda açıkça öngörülmesi,
                      <br />
                      Fiili imkânsızlık nedeniyle rızasını açıklayamayacak
                      durumda bulunan veya rızasına hukuki geçerlilik tanınmayan
                      kişinin kendisinin ya da bir başkasının hayatı veya beden
                      bütünlüğünün korunması için zorunlu olması,
                      <br />
                      Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                      ilgili olması kaydıyla, sözleşmenin taraflarına ait
                      Kişisel Verilerin işlenmesinin gerekli olması, Veri
                      sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                      için zorunlu olması,
                      <br />
                      İlgili kişinin kendisi tarafından alenileştirilmiş olması,
                      <br />
                      Bir hakkın tesisi, kullanılması veya korunması için veri
                      işlemenin zorunlu olması,
                      <br />
                      İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                      kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                      işlenmesinin zorunlu olması halinde yurtdışına
                      aktarılabilmektedir.
                      <br />
                      <br />
                      <span>Özel nitelikli Kişisel Veriler ise,</span>
                      <br />
                      Kişisel Verilerin aktarılacağı ülkede yeterli korumanın
                      bulunması halinde, sağlık ve cinsel hayat dışındaki
                      Kişisel Veriler kanunda açıkça öngörülmesi halinde
                      yurtdışına aktarılabilecektir.
                      <br />
                      Yeterli korumaya sahip ülkelerde kişilerin, sağlık ve
                      cinsel hayata ilişkin Kişisel Verileri ise ancak kamu
                      sağlığının korunması, koruyucu hekimlik, tıbbi teşhis,
                      tedavi ve bakım hizmetlerinin yürütülmesi, sağlık
                      hizmetleri ile finansmanının planlanması ve yönetimi
                      amacıyla, sır saklama yükümlülüğü altında bulunan kişiler
                      veya yetkili kurum ve kuruluşlar tarafından ilgilinin Açık
                      Rızası aranmaksızın yurtdışına aktarılabilecektir.
                      <br />
                      Yeterli korumanın bulunduğu ülkeler Kurul tarafından ilan
                      edilecektir.
                      <br />
                      <br />
                      <strong>
                        B) Yeterli korumaya sahip olmayan ülkelere veri aktarımı
                        için;
                      </strong>
                      <br />
                      Kanunun 5 veya 6. Maddesinde sayılan şartlardan en az
                      birinin gerçekleşmesi,
                      <br />
                      Türkiye’deki ve ilgili yabancı ülkedeki veri
                      sorumlularının yeterli bir korumayı yazılı olarak taahhüt
                      etmeleri,
                      <br />
                      Kurulun izninin bulunması gerekmektedir.
                      <br />
                      <br />
                      Kanunda öngörülen bu durumlar dışında (güvenli ülke,
                      taahhüt alınması){" "}
                      <strong>
                        Kişisel Verilerin yurt dışı ile paylaşımı Açık Rızaya
                        dayanmalıdır.
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        DİĞER ŞİRKETLERLE (Örn. Grup Şirketleri) VERİ PAYLAŞIMI
                      </strong>
                    </td>
                    <td>
                      Paylaşılan verinin niteliği ve sürece göre ilgiliden Açık
                      Rıza alınması gerekebilecektir.
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                <strong>
                  X. PROSEDÜRÜN YÜRÜRLÜĞE SOKULMASI, İHLAL DURUMLARI VE
                  YAPTIRIMLAR
                </strong>
              </p>

              <p>
                İşbu PROSEDÜR tüm çalışanlara duyurularak yürürlüğe girecek ve
                yürürlüğü itibariyle tüm iş birimleri, danışmanlar, dış hizmet
                sağlayıcıları ve sair Şirket nezdinde Kişisel Veri işleyen
                herkes için bağlayıcı olacaktır.
              </p>

              <p>
                Şirket çalışanlarının Prosedürün gereklerini yerine getirip
                getirmediğinin takibi ilgili çalışanların amirlerinin
                sorumluluğunda olacaktır. Prosedür’ e aykırı davranış tespit
                edildiğinde konu derhal ilgili çalışanın amiri tarafından bağlı
                bulunulan bir üst amire bildirilecektir.
              </p>

              <p>
                Prosedür’e aykırı davranan çalışan hakkında, İnsan Kaynakları
                tarafından yapılacak değerlendirme sonrasında gerekli idari
                işlem yapılacaktır.
              </p>

              <p>
                Prosedür gereklerinin yerine getirilmesi için Şirket tarafından;
                Kurulun öngördüğü tedbirler de dahil olmak üzere gerekli tüm
                güvenlik önlemleri alınacaktır.
              </p>

              <p>
                <strong>XI. YÜRÜRLÜK VE GÜNCELLEME</strong>
              </p>

              <p>
                Prosedür{" "}
                <strong>
                  yayınlanma tarihi itibari ile yürürlüğe girecektir
                </strong>
                . Prosedürün Şirket genelinde duyurulması ve gerekli
                güncellemelerin yapılması Komite’nin sorumluluğundadır.
              </p>
            </ModalBody>
          </Modal>
          <Modal isOpen={modalKvkk} size="xl" toggle={toggleKvkk}>
            <ModalHeader toggle={toggle} tag="h4">
              6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU (“KVKK”) USUL VE
              ESASLARI UYARINCA KİŞİSEL VERİLERİN KORUNMASI HAKKINDA ÇALIŞAN
              ADAYI AYDINLATMA METNİ
            </ModalHeader>
            <ModalBody>
              <div>
                <p>Bu QR'da iş var olarak; veri sorumlusu sıfatıyla,</p>

                <ul>
                  <li>
                    ticari ilişkilerimiz kapsamında ve tarafınızla yapılacak
                    olan iş görüşmeleri çerçevesinde,
                  </li>
                  <li>
                    işleme amacı ile bağlantılı, sınırlı ve ölçülü şekilde,
                  </li>
                  <li>
                    duruma göre bizim talep ettiğimiz ve/veya sizlerin bizimle
                    paylaşmış olduğunuz kişisel verilerinizin, işlenmelerini
                    gerektiren amaç çerçevesinde;
                  </li>
                </ul>

                <p>
                  kaydedileceğini, depolanacağını, muhafaza edileceğini, yeniden
                  düzenleneceğini, kanunen bu kişisel verileri talep etmeye
                  yetkili olan kurumlar ile paylaşılacağını ve KVKK’nın
                  öngördüğü şartlarda, yurtiçi veya yurtdışı üçüncü kişilere
                  aktarılacağını, devredileceğini, sınıflandırılabileceğini ve
                  KVKK’da sayılan sair şekillerde işlenebileceğini bildiririz.
                </p>

                <p>
                  <strong>İŞLENEN KİŞİSEL VERİLERİNİZ</strong>
                </p>

                <p>
                  Tarafımızca çalışan adaylarına ilişkin işlemeye konu
                  olabilecek kişisel veriler şu şekildedir: Ad-soyadı, cinsiyet,
                  TC Kimlik Numarası, milliyeti, medeni durumu, doğum yeri ve
                  tarihi, kimlik fotokopisi, pasaport numarası, telefon
                  numarası, açık adres bilgisi, e-posta adresi, sosyal medya
                  hesabı, maaş detayları, öğrenim durumu, eğitim ve beceriler,
                  sertifika ve diplomalara ait detaylar, son mezun olduğu okul
                  ve mezuniyet yılı, vesikalık fotoğraf, kapalı devre güvenlik
                  kamerası görüntüleri, mevcut ve önceki iş bilgisi, iş unvanı,
                  CV, aday başvuru formları, fiziksel ya da psikolojik hastalık,
                  bozukluk, engellilik, bulaşıcı hastalık durumu, kimlik
                  fotokopisi içerisinde yer alan din bilgisi, kan grubu hanesi,
                  askerlik durumu, tecil belgesi ve süresi, sabıka kaydı,
                  alkol/uyuşturucu problemi gibi.
                </p>

                <p>
                  <strong>KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI</strong>
                </p>

                <p>
                  Şirket tarafından kişisel verileriniz aşağıda belirtilen amaç
                  ve hukuki sebepler gibi ancak bunlarla sınırlı olmayan amaç ve
                  sebeplerle işlenebilir.
                </p>

                <ul>
                  <li>
                    İşe alım süreçlerinin yürütülmesi, kayda alınması ve
                    iyileştirilmesi,
                  </li>
                  <li>
                    İş görüşmeleri esnasında kişinin beyan ettiği
                    tecrübelerinin, eğitim durumunun, yetkinliklerinin
                    araştırma, gözlem ve kaydının yapılması,
                  </li>
                  <li>
                    İstihdam edilecek çalışan adayının görevlendirileceği ilgili
                    departmanın iş koşullarına uygun eğitim, nitelik ve
                    becerilerinin mevcudiyetinin tespiti,
                  </li>
                  <li>
                    İşle bağlantılı belirli fonksiyonlar açısından özel
                    risklerin tespiti amacıyla çalışan adayının kamuya açık
                    bilgilerinin incelenmesi,
                  </li>
                  <li>
                    Görüşme sonunda istihdamın gerçekleşmemesi durumunda,
                    ileride adayın uygun bir pozisyon için görüşmeye çağrılması,
                  </li>
                  <li>
                    Duruma göre idari ve resmi mercilerin taleplerinin
                    karşılanması,
                  </li>
                  <li>İnsan kaynakları yönetimi verimliliğinin sağlanması,</li>
                  <li>Şirket içi güvenliğin sağlanması</li>
                </ul>

                <p>
                  Kişisel verileriniz, ilgili mevzuatta belirtilen veya
                  işlendikleri amaç için gerekli olan süre kadar muhafaza
                  edilecektir.
                </p>

                <p>
                  <strong>
                    KİŞİSEL VERİLERİNİZİN YURTİÇİNDEKİ VE YURT DIŞINDAKİ ÜÇÜNCÜ
                    KİŞİLERLE PAYLAŞILMASI
                  </strong>
                </p>

                <p>
                  Şirketimiz, hizmet vermekte olduğu kurumsal müşterilerin talep
                  etmesi halinde veya müşteri firmalarla imzalanan ticari
                  sözleşmeler gereği, ilgili Müşteri operasyonlarında görev
                  yapmak üzere istihdam edilecek adayların verilerini duruma
                  göre yurt içi veya yurt dışında yerleşik ilgili Müşteriye
                  KVKK’ya uygun olarak aktarmaktadır.
                </p>

                <p>
                  Şirketimiz, veri sorumlusu sıfatı ile işlediği çalışan
                  adaylarına ait kişisel verileri Müşteri firmalar ile genel
                  olarak çalışan aday profilinin müşteri operasyonuna
                  yatkınlığının tespiti amacıyla aktarmaktadır.
                </p>

                <p>
                  <strong>KVKK’NIN 11. MADDESİ UYARINCA HAKLARINIZ</strong>
                </p>

                <p>
                  KVKK’nın 11. maddesi gereği bize şahsen, kimliğinizi ispat
                  etmeniz kaydıyla, kişisel verileriniz ile ilgili;
                </p>

                <ul>
                  <li>
                    Şirket’in hakkınızda kişisel veri işleyip işlemediğini
                    öğrenmek, eğer işlemişse, buna ilişkin bilgi talep etmek,
                  </li>
                  <li>
                    Kişisel verilerinizin işlenme amacını ve bunların amacına
                    uygun kullanılıp kullanılmadığı öğrenmek,
                  </li>
                  <li>
                    Kişisel verilerin yurtiçi veya yurtdışına aktarılıp
                    aktarılmadığı ve kimlere aktarıldığını öğrenmek hakkına
                    sahipsiniz.
                  </li>
                </ul>

                <p>
                  Ayrıca, Şirket’ten yanlış ve eksik kişisel verilerinizin
                  düzeltilmesini ve verilerinin aktarıldığı veya aktarılmış
                  olabileceği alıcıların bilgilendirilmesini talep etme hakkınız
                  vardır. Kişisel verilerinizin KVKK madde 7’de öngörülen
                  şartlar çerçevesinde verilerinizin silinmesini ve yok
                  edilmesini Şirketten talep edebilirsiniz. Münhasıran bir
                  otomatik sistem kullanılarak oluşturulmuş kişisel veri
                  analizinizin sonuçlarına bu sonuçlar çıkarlarınıza aykırıysa
                  itiraz edebilirsiniz. Kişisel verilerinizin kanuna aykırı
                  olarak işlenmesi sebebiyle zarara uğramanız halinde zararın
                  giderilmesini talep edebilirsiniz.
                </p>

                <p>
                  Aynı zamanda verilerin aktarıldığı veya aktarılabileceği 3.
                  kişilerin söz konusu silme ve yok edilme hususlarında
                  bilgilendirilmelerini talep edebilirsiniz. Başvurunuzda yer
                  alan talepleriniz, talebin niteliğine göre en geç otuz gün
                  içinde ücretsiz olarak sonuçlandırılacaktır.
                </p>
              </div>
            </ModalBody>
          </Modal>
          {success_dlg || (!isEmpty(success) && success == true) ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
                dispatch(onResetPlan())
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Do you want to submit your application?")}
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("GoBack")}
              confirmBtnText={props.t("Send")}
              onConfirm={() => {
                setconfirm_alert(false)
                //setsuccess_dlg(true);
                setdynamic_title(props.t(""))
                setdynamic_description("")
                dispatch(onAddAppForm(form, selectedFiles))
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {/* {props.t("DeleteWarning")} */}
            </SweetAlert>
          ) : null}

          {confirm_kvkk ? (
            <SweetAlert
              title={""}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setconfirm_kvkk(false)
              }}
            >
              {"Kişisel verilerin korunması sözleşmesi boş bırakılamaz"}
            </SweetAlert>
          ) : null}
          {confirm_Identitiy ? (
            <SweetAlert
              title={""}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setconfirm_Identity(false)
              }}
            >
              {"Lütfen Gerçek TC Kimlik Numaranızı Girin!"}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetPlan())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Plans))
