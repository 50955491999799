import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { authorize, Role } from "./../helpers/authorize"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [adminPanel, setadminPanel] = useState(false)
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [component, setcomponent] = useState(false)
  const [component2, setcomponent2] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)

  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)
  const [extra, setextra] = useState(false)
  const [invoice, setinvoice] = useState(false)
  const [auth, setauth] = useState(false)
  const [utility, setutility] = useState(false)

  return (
    <React.Fragment>
      <Row> 
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
               
                {authorize([ Role.Admin , Role.YetkiliUser,Role.Ik,Role.YetkiliIk,Role.Avm]) ? (
                  <li className="nav-item dropdown">
                    <Link
                      to="/dashboard"
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-layer me-2"></i>
                      {props.t("Application Pool")} 
                    </Link>
                  </li>
                ) : null} 
                  {authorize([Role.Admin,Role.YetkiliIk ]) ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/plans"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-pen me-2"></i>
                        {props.t("Ad Pool")}
                      </Link>
                    </li>
                  ) : null}
                  {authorize([Role.Admin, Role.Dev, Role.User, Role.YetkiliUser,Role.Ik,Role.YetkiliIk]) ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/requestForm"
                        className="nav-link dropdown-toggle arrow-none "
                      >
                        <i className="bx bx-pen me-2"></i>
                        {props.t("Staff Request Forms")}
                      </Link>
                    </li>
                  ) : null}

                  {authorize([Role.Dev]) ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/companies"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-chevron-up-square me-2"></i>
                        {props.t("Companies")}
                      </Link>
                    </li>
                  ) : null}
                 
                  {/* {authorize([Role.Admin]) ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/companyInfo"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-chevron-up-square"></i>
                        {props.t("Company Info")}
                      </Link>
                    </li>
                  ) : null} */}

                  {authorize([Role.Dev, Role.Admin,Role.YetkiliIk]) ? ( 
                    <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setcomponent2(!component2)
                      }}
                    >
                      <i className="bx bx-trending-up me-2"></i>
                      {props.t("Analytical Data")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: component2,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to={"/analytics/branches"}
                          className="dropdown-item"
                        >
                          <i className="mdi mdi-qrcode me-1"></i>{props.t("qrCode")}<span className="badge badge-soft-danger ms-2">{props.t("alfa-test")}</span>
                        </Link>
                        <div></div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to={"/analytics"}
                          className="dropdown-item"
                        >
                          <i className="mdi mdi-trending-up me-1"></i>{props.t("Graphics")}<span className="badge badge-soft-danger ms-2">{props.t("alfa-test")}</span>
                        </Link>
                        <div></div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to={"/report"}
                          className="dropdown-item"
                        >
                          <i className="bx bx-clipboard me-1"></i>{props.t("Reports")}<span className="badge badge-soft-danger ms-2">{props.t("alfa-test")}</span>
                        </Link>
                        <div></div>
                      </div>
                     
                      
                    </div>
                  </li>
                  ) : null}
                   {authorize([Role.Admin,Role.Ik,Role.YetkiliIk]) ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/planFromOutside"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-user me-2"></i>
                        {props.t("Platform Dışı Personel")}
                      </Link>
                    </li>
                  ) : null}
                  {/* {authorize([Role.Dev, Role.Admin]) ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/branches"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-store-alt"></i>
                        {props.t("Branchs")}
                      </Link>
                    </li>) :
                    null}

                  {authorize([Role.Admin]) ? (
                    <li className="nav-item dropdown">
                      <Link
                        to={"/companies/users/" + user.companyId}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-user"></i>
                        {props.t("My Users")}
                      </Link>
                    </li>) :
                    null} */}
                  {/* {authorize([Role.Admin]) ? (
                    <li className="nav-item dropdown">

                      <div className="nav-link dropdown-toggle arrow-none">


                        <i className="bx bx-file"></i>
                        {props.t("Definitions")} {props.menuOpen}
                        <div className="arrow-down" ></div>
                      </div>
                      <div
                        className={classname("dropdown-menu", { show: adminPanel })}
                      >
                        <Link to={"/companies/users/" + user.companyId} className="dropdown-item">
                          {props.t("Define User")}
                        </Link>
                        <Link to="/branches" className="dropdown-item">
                          {props.t("Define Company")}
                        </Link>
                      </div> 
                    </li>) :
                    null} */}
                  {authorize([Role.Dev, Role.Admin , Role.YetkiliIk]) ?(
                    <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setcomponent(!component)
                      }}
                    >
                      <i className="bx bx-collection me-2"></i>
                      {props.t("Definitions")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: component,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to={"/companies/users/" + user.companyId}
                          className="dropdown-item"
                        > 
                          {props.t("Define User")}
                        </Link>
                        <div></div>
                      </div>

                      {authorize([Role.Admin,Role.YetkiliIk]) ? (
                        <div className="dropdown">
                          <Link to="/branches" className="dropdown-item">
                            {props.t("Define Company")}
                          </Link>
                        </div>
                      ) : null}
                       {/* {authorize([Role.Admin]) ? ( 
                        <div className="dropdown">
                          <Link to="/duty" className="dropdown-item">
                            {props.t("Define Duty")}
                          </Link>
                        </div>
                      ) : null}
                      {authorize([Role.Admin]) ? ( 
                        <div className="dropdown">
                          <Link to="/softSkill" className="dropdown-item">
                            {props.t("Soft Skill")}
                          </Link>
                        </div>
                      ) : null}
                      {authorize([Role.Admin]) ? ( 
                        <div className="dropdown">
                          <Link to="/foreignLanguage" className="dropdown-item">
                            {props.t("Yabancı Dil")}
                          </Link>
                        </div>
                      ) : null}
                      {authorize([Role.Admin]) ? ( 
                        <div className="dropdown">
                          <Link to="/program" className="dropdown-item">
                            {props.t("Program")}
                          </Link>
                        </div>
                      ) : null} */}
                      {authorize([Role.Dev]) ? ( 
                        <div className="dropdown">
                          <Link to="/devDuty" className="dropdown-item">
                            {props.t("Define Duty")}
                          </Link>
                        </div>
                      ) : null}
                       
                     
                       {authorize([Role.Dev]) ? ( 
                        <div className="dropdown">
                          <Link to="/devSectorForm" className="dropdown-item">
                            {props.t("Sektör Tanımla")}
                          </Link> 
                        </div>
                      ) : null}
                       {authorize([Role.Dev]) ? ( 
                        <div className="dropdown">
                          <Link to="/devForeignLanguage" className="dropdown-item">
                            {props.t("Yabancı Dil Tanımla")}
                          </Link>
                        </div>
                      ) : null}
                       {authorize([Role.Dev]) ? ( 
                        <div className="dropdown">
                          <Link to="/devProgram" className="dropdown-item">
                            {props.t("Program Tanımla")}
                          </Link>
                        </div>
                      ) : null}
                       {authorize([Role.Dev]) ? ( 
                        <div className="dropdown">
                          <Link to="/devDutyForm" className="dropdown-item">
                            {props.t("Form İçi Görev")}
                          </Link>
                        </div>
                      ) : null}
                       {authorize([Role.Dev]) ? ( 
                        <div className="dropdown">
                          <Link to="/devWorkType" className="dropdown-item">
                            {props.t("Çalışma Yöntemi")}
                          </Link>
                        </div>
                      ) : null}
                       {authorize([Role.Dev]) ? ( 
                        <div className="dropdown">
                          <Link to="/DevUser" className="dropdown-item">
                            {props.t("Şirketler/Kullanıcılar")}
                          </Link>
                        </div>
                      ) : null}
                      {authorize([Role.Dev]) ? ( 
                        <div className="dropdown">
                          <Link to="/DevRequestReason" className="dropdown-item">
                            {props.t("Personel Talep Sebebi")}
                          </Link>
                        </div>
                      ) : null}
                      
                     
                    </div>
                  </li>
                  ):null}
             
                  
                     {authorize([Role.Avm]) ?(
                   
                   <li className="nav-item dropdown">
                   <Link
                     to="/applicationApproval"
                     className="nav-link dropdown-toggle arrow-none"
                   >
                     <i className="bx bx-layer me-2"></i>
                     {props.t("Application Approval")} 
                   </Link>
                 </li> 
                 
                  ):null}
                  {authorize([Role.Avm]) ?(
                   
                   <li className="nav-item dropdown">
                   <Link
                     to="/advertisement"
                     className="nav-link dropdown-toggle arrow-none"
                   >
                     <i className="bx bx-layer me-2"></i>
                     {props.t("Advertisement")} 
                   </Link>
                 </li> 
                 
                  ):null}
                  {authorize([Role.Avm]) ?(
                   
                   <li className="nav-item dropdown">
                   <Link
                     to="/avmInfo" 
                     className="nav-link dropdown-toggle arrow-none"
                   >
                     <i className="bx bx-layer me-2"></i>
                     {props.t("Formlar için Fotoğraf")} 
                   </Link>
                 </li> 
                 
                  ):null}
                    {authorize([Role.Dev]) ?(
                   
                   <li className="nav-item dropdown">
                   <Link
                     to="/sector"
                     className="nav-link dropdown-toggle arrow-none"
                   >
                     <i className="bx bx-layer me-2"></i>
                     {props.t("Sektör")} 
                   </Link>
                 </li> 
                 
                  ):null}
                    {authorize([Role.Dev]) ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/status"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-chevron-up-square me-2"></i>
                        {props.t("Sınıflar")}
                      </Link>
                    </li>
                  ) : null}
                   {authorize([Role.Dev]) ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/loginLog"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-user me-2"></i>
                        {props.t("Kullanıcı Logları")}
                      </Link>
                    </li>
                  ) : null}
                  

                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
        
      </Row>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
