import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"

import {
  Col,
  Container,
  Form,
  Alert,
  FormGroup,
  Label,
  Row,
  Input,
  Spinner,
} from "reactstrap"

import logodark from "../../assets/images/bu_qrda_is_var_1.png"
import logolight from "../../assets/images/bu_qrda_is_var_1.png"
import CarouselPage from "./CarouselPage"

//i18n
import { withTranslation } from "react-i18next"

import { emailConfirm as onPostEmailConfirm } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"
 

const Confirm = props => {
  const dispatch = useDispatch()

  const { error, loading, data } = useSelector(state => ({
    loading: state.ConfirmReducer.loading,
    error: state.ConfirmReducer.error,
    data: state.ConfirmReducer.data,
  }))

  const {
    match: { params },
  } = props

  useEffect(() => {
    dispatch(onPostEmailConfirm(params.id, params.hash))
  }, [dispatch])

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>{props.t("Confirm")}</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="225"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="225"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div className="text-center">
                        <h5 className="text-primary">
                          {loading
                            ? props.t("Confirm Control Please Wait..")
                            : null}
                        </h5>
                        {loading
                            ? <Spinner animation="border" variant="primary" />
                            : null}
                        <div className="text-muted">
                          {data ? (
                            <div className="alert alert-success h3">
                              {props.t("Confirm Success")} 
                              <br />
                              <i className="fa fa-check"></i>
                              <hr /> 
                              {
                                //TODO: Sifre Olustur Bolumu Burada Olmali
                              }
                              <Link to="/Login" className="font-size-13">{props.t("Login")}</Link>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {error ? (
                            <div className="alert alert-danger">{error?.message || props.t("Failed")}  <i className="fa fa-times"></i></div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      <div className="mt-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Confirm))

Confirm.propTypes = {
  history: PropTypes.object,
}
